<template>
    <div id="mbkm_detail">
        <div class="mcontainer">
            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-lg font-semibold">Account</h2>
                    <p>Profil anda</p>
                </div>
                <div class="flex align-items-center">
                    <router-link :to="{ name: 'AccountFriends' }" class="flex mr-5 mb-0 align-items-center">
                        <span class="d-inline mr-2">
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3.3335C3 4.9875 4.346 6.3335 6 6.3335C7.654 6.3335 9 4.9875 9 3.3335C9 1.6795 7.654 0.333496 6 0.333496C4.346 0.333496 3 1.6795 3 3.3335ZM11.3333 13.0002H12V12.3335C12 9.76083 9.906 7.66683 7.33333 7.66683H4.66667C2.09333 7.66683 0 9.76083 0 12.3335V13.0002H11.3333Z" fill="currentColor"/>
                            </svg>
                        </span>
                        <span>
                            {{ datauser.friends.length }} Friends
                        </span>
                    </router-link>
                    <router-link :to="{ name: 'AccountFriendsUnconfirmed' }" class="flex mr-5 mb-0 align-items-center">
                        <span class="d-inline mr-2">
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3.3335C3 4.9875 4.346 6.3335 6 6.3335C7.654 6.3335 9 4.9875 9 3.3335C9 1.6795 7.654 0.333496 6 0.333496C4.346 0.333496 3 1.6795 3 3.3335ZM11.3333 13.0002H12V12.3335C12 9.76083 9.906 7.66683 7.33333 7.66683H4.66667C2.09333 7.66683 0 9.76083 0 12.3335V13.0002H11.3333Z" fill="currentColor"/>
                            </svg>
                        </span>
                        <span>
                            {{ datauser.friends_waiting_confirmation.length }} Waiting
                        </span>
                    </router-link>
                    <b-dropdown class="flex items-center" variant="primary" no-caret>
                        <template #button-content>
                            <Icon icon="ph:dots-three-outline-vertical-fill" class="d-inline" />
                        </template>
                        <b-dropdown-item @click="generateCV">Download PDF</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="d-flex justify-content-end mb-3">
                <button class="btn btn-outline-primary relative mr-2" @click="changeBgShow">
                    <Icon icon="bi:image" class="d-inline" /> Image Background
                </button>
                <button class="btn btn-primary relative">
                    <Icon icon="clarity:color-picker-solid" class="d-inline" /> Sidebar Color
                    <InputColorPicker class="color-picker" v-model="cv_color" @change="handleChangeColor" />
                </button>
            </div>
            <div class="card mt-5 cv-background" :style="'background-image: url(' + cv_background_temp + ');'">
                <div class="row">
                    <div class="col-md-4" style="min-height: 800px;">
                        <div class="sidebar-cv" :style="'background: ' + cv_color">
                            <div class="header-cv">
                                <img :src="datauser.images ? datauser.images : $placeholder2" alt="images" class="profile-picture">
                                <h3 class="name-cv">{{ datauser.fullname }} <router-link to="/app/setting"><Icon icon="heroicons-solid:pencil-alt" class="d-inline float-right" /></router-link></h3>
                                <p>{{ datauser.faculity }}</p>
                                <table>
                                    <tr v-if="datauser.no_induk !== '' && datauser.no_induk !== null">
                                        <td><Icon icon="bx:bx-id-card" /></td>
                                        <td><p>{{ datauser.no_induk }}</p></td>
                                    </tr>
                                </table>
                            </div>
                            <h5 class="title-cv">ABOUT <router-link to="/app/setting"><Icon icon="heroicons-solid:pencil-alt" class="d-inline float-right" /></router-link></h5>
                            <table>
                                <tr v-if="datauser.ttl !== '' && datauser.ttl !== null">
                                    <td><Icon icon="ic:round-date-range" /></td>
                                    <td><p>{{ datauser.ttl }}</p></td>
                                </tr>
                            </table>
                            <p style="white-space: pre-line;">{{ datauser.about }}</p>
                            <br><br>
                            <h5 class="title-cv">CONTACT <router-link to="/app/setting"><Icon icon="heroicons-solid:pencil-alt" class="d-inline float-right" /></router-link></h5>
                            <table>
                                <tr v-if="datauser.phone !== '' && datauser.phone !== null">
                                    <td><Icon icon="bx:bxs-phone-call" /></td>
                                    <td><p>{{ datauser.phone }}</p></td>
                                </tr>
                                <tr v-if="datauser.email !== '' && datauser.email !== null">
                                    <td><Icon icon="ic:baseline-email" /></td>
                                    <td><p>{{ datauser.email }}</p></td>
                                </tr>
                                <tr v-if="datauser.ig_username !== '' && datauser.ig_username !== null">
                                    <td><Icon icon="ph:instagram-logo-fill" /></td>
                                    <td><p>{{ datauser.ig_username }}</p></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="container-cv">
                            <h5 class="title-cv">Skills <a href="javascript:void(0)" @click.prevent="addItem('Skills')"><span class="btn-add"><Icon icon="eva:plus-outline" /></span></a></h5>
                            <table>
                                <tr v-for="item in dataItemUser.skills" :key="item.id">
                                    <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                    <td :colspan="item.images === '' ? 2 : 0">
                                        <span class="btn-edit-outline" @click.prevent="editItem(item, 'Skills')">Edit</span>
                                        <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                        <p style="margin-top: 0px;white-space: pre-line"><strong>{{ item.title }}</strong><br>{{ item.description }}</p>
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <h5 class="title-cv">Education <a href="javascript:void(0)" @click.prevent="addItem('Education')"><span class="btn-add"><Icon icon="eva:plus-outline" /></span></a></h5>
                            <table>
                                <tr v-for="item in dataItemUser.education" :key="item.id">
                                    <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                    <td :colspan="item.images === '' ? 2 : 0">
                                        <span class="btn-edit-outline" @click.prevent="editItem(item, 'Education')">Edit</span>
                                        <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                        <strong>{{ item.title }}</strong>
                                        <p style="white-space: pre-line;">
                                            <b>{{ item.subtitle }}</b> <span class="opacity-75">{{ formatDateThreeMonth(item.start_date) }} - {{ formatDateThreeMonth(item.end_date) !== '' ? formatDateThreeMonth(item.end_date) : 'Now' }}</span><br>
                                            {{ item.description }}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <h5 class="title-cv">License and Certification <a href="javascript:void(0)" @click.prevent="addItem('Certification')"><span class="btn-add"><Icon icon="eva:plus-outline" /></span></a></h5>
                            <table>
                                <tr v-for="item in dataItemUser.certification" :key="item.id">
                                    <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                    <td :colspan="item.images === '' ? 2 : 0">
                                        <span class="btn-edit-outline" @click.prevent="editItem(item, 'Certification')">Edit</span>
                                        <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                        <strong>{{ item.title }}</strong>
                                        <p style="white-space: pre-line;">
                                            <b>{{ item.subtitle }}</b> <span class="opacity-75">Diterbitkan {{ formatDateMonth(item.start_date) }}</span><br>
                                            {{ item.description }}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <h5 class="title-cv">Achievement <a href="javascript:void(0)" @click.prevent="addItem('Achievement')"><span class="btn-add"><Icon icon="eva:plus-outline" /></span></a></h5>
                            <table>
                                <tr v-for="item in dataItemUser.achievement" :key="item.id">
                                    <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                    <td :colspan="item.images === '' ? 2 : 0">
                                        <span class="btn-edit-outline" @click.prevent="editItem(item, 'Achievement')">Edit</span>
                                        <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                        <strong>{{ item.title }}</strong>
                                        <p style="white-space: pre-line;">
                                            <b>{{ item.subtitle }}</b><br>
                                            {{ item.description }}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                            <br>
                            <h5 class="title-cv">Experience <a href="javascript:void(0)" @click.prevent="addItem('Experience')"><span class="btn-add"><Icon icon="eva:plus-outline" /></span></a></h5>
                            <table>
                                <tr v-for="item in dataItemUser.experience" :key="item.id">
                                    <td v-if="item.images !== ''"><img :src="item.images" alt="PLACEHOLDER" class="icon-cv"></td>
                                    <td :colspan="item.images === '' ? 2 : 0">
                                        <span class="btn-edit-outline" @click.prevent="editItem(item, 'Experience')">Edit</span>
                                        <span class="btn-edit-outline btn-delete" @click.prevent="deleteItem(item.id)"><Icon icon="bi:trash" /></span>
                                        <strong>{{ item.title }}</strong>
                                        <p style="white-space: pre-line;">
                                            <b>{{ item.subtitle }}</b> <span class="opacity-75">{{ formatDateThreeMonth(item.start_date) }} - {{ formatDateThreeMonth(item.end_date) !== '' ? formatDateThreeMonth(item.end_date) : 'Now' }}</span><br>
                                            {{ item.description }}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="accountitem.form.show" :title="accountitem.form.title" ok-title="Submit" :hide-footer="true">
            <form @submit.prevent="">
                <b-form-group>
                    <div class="image-uploader mb-3">
                        <input type="file" ref="images" @change="onFileSelected($event, 'images')">
                        <span @click="$refs.images.click()"><Icon icon="bi:cloud-upload" /></span>
                        <img :src="images ? images : $placeholder2" alt="Images" class="w-20">
                    </div>
                </b-form-group>
                <b-form-group>
                    <b-form-input
                        v-model="accountitem.form.data.title"
                        :placeholder="type === 'Education' ? 'Add a Institution' : type === 'Certification' ? 'Add a License' : type === 'Experience' ? 'Add a Position' : 'Add a Title'"
                    ></b-form-input>
                </b-form-group>
                <b-form-group v-if="type !== 'Skills'">
                    <b-form-input
                        v-model="accountitem.form.data.subtitle"
                        :placeholder="type === 'Education'? 'Add a Major' : type === 'Certification' || type === 'Experience' ? 'Add a Company' : 'Add a Subtitle'"
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <div class="row">
                        <div class="col-md-6" v-if="type !== 'Skills' && type !== 'Achievement'">
                            <datepicker v-model="accountitem.form.data.start_date" :bootstrap-styling="true" placeholder="Start Date" class="custom-input"></datepicker>
                        </div>
                        <div class="col-md-6" v-if="type !== 'Skills' && type !== 'Certification' && type !== 'Achievement'">
                            <datepicker v-model="accountitem.form.data.end_date" :bootstrap-styling="true" placeholder="End Date" class="custom-input"></datepicker>
                            <small class="text-form-control" v-if="type === 'Experience' || type === 'Education'">Leave it blank if it's still active</small>
                        </div>
                    </div>
                </b-form-group>
                <b-form-group>
                    <b-form-textarea
                        v-model="accountitem.form.data.description"
                        placeholder="Add a Description"
                        rows="3"
                    ></b-form-textarea>
                </b-form-group>
                <b-form-group align="right">
                    <div>
                        <b-button v-if="accountitem.form.data.id" variant="danger" class="mr-2" @click="deleteItem">
                            Delete
                        </b-button>
                        <b-button variant="primary" type="submit" @click="submitItem" size="sm">
                            Submit
                        </b-button>
                    </div>
                </b-form-group>
            </form>
        </b-modal>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="'Curiculum Vitae - ' + datauser.fullname"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            :margin="0"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <CV :user="datauser" :items="dataItemUser" :type="'personal'" />
            </section>
        </vue-html2pdf>

        <modal name="change-background" :height="400" :width="600">
            <div class="p-3">
                <div class="d-flex justify-content-end align-items-center">
                    <a href="" @click.prevent="changeBgClose">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z" fill="currentColor"/>
                        </svg>
                    </a>
                </div>

                <div class="drop-uploader" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <center>
                        <input type="file" ref="file_cv_bg" name="cv_background" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChangeCVBg($event)" accept=".jpg,.jpeg,.png" />
                        <img :src="cv_background_temp ? cv_background_temp : $placeholder2" alt="Images" class="w-20 mb-3" v-if="cv_background_temp !== ''">
                        <Icon icon="ion:cloud-upload-outline" class="fs-40 mb-3" v-else />
                        <p><b>Select a file or drag and drop here</b></p>
                        <p>JPG or PNG, file size no more than 10MB</p>
                        <button class="btn btn-outline-primary mt-4" @click="$refs.file_cv_bg.click()">Select File</button>
                        <button class="btn btn-primary ml-2 mt-4" @click="updateBg">Update</button>
                    </center>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    import CV from '@/components/CuriculumVitae'
    import { mapState, mapActions } from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import VueHtml2pdf from 'vue-html2pdf'
    import axios from 'axios'
    import InputColorPicker from 'vue-native-color-picker'

    export default {
        name: 'Account',
        computed: {
            ...mapState('auth', { datauser: 'user' }),
            ...mapState('accountItem', {
                dataItem: state => state.data
            })
        },
        components: {
            Icon,
            Datepicker,
            VueHtml2pdf,
            CV,
            InputColorPicker
        },
        data () {
            return {
                cv_background: '',
                cv_background_temp: '',
                cv_color: '#2e74b0',
                accountitem: {
                    form: {
                        title: '',
                        show: false,
                        loading: false,
                        data: {
                            id: '',
                            type: '',
                            title: '',
                            subtitle: '',
                            description: '',
                            start_date: '',
                            end_date: '',
                            images: ''
                        }
                    }
                },
                type: '',
                images: '',
                dataItemUser: {
                    skills: [],
                    achievement: [],
                    certification: [],
                    education: [],
                    experience: []
                }
            }
        },
        methods: {
            ...mapActions('accountItem', {
                _createItem: 'create',
                _updateItem: 'update',
                _deleteItem: 'delete',
                _fetchItem: 'fetch'
            }),
            submitItem () {
                this.accountitem.form.loading = true
                const { id, type, title, subtitle, description, images } = this.accountitem.form.data
                const startDate = this.formatDateStore(this.accountitem.form.data.start_date)
                const endDate = this.formatDateStore(this.accountitem.form.data.end_date)
                const promise = id ? this._updateItem({ id, data: { type, title, subtitle, description, images, start_date: startDate, end_date: endDate } }) : this._createItem({ type, title, subtitle, description, images, start_date: startDate, end_date: endDate })
                promise.then(() => {
                    this._fetchItem(this.datauser.username).then((result) => {
                        this.dataItemUser = result
                    })
                    this.accountitem.form.show = false
                })
                    .finally(() => {
                        this.accountitem.form.loading = false
                    })
            },
            addItem (type) {
                this.accountitem.form.title = 'Add ' + type
                this.accountitem.form.show = true
                this.accountitem.form.data.id = ''
                this.accountitem.form.data.type = type
                this.accountitem.form.data.title = ''
                this.accountitem.form.data.subtitle = ''
                this.accountitem.form.data.description = ''
                this.accountitem.form.data.images = ''
                this.accountitem.form.data.start_date = ''
                this.accountitem.form.data.end_date = ''
                this.images = ''
                this.type = type
            },
            editItem (item, type) {
                this.accountitem.form.title = 'Edit ' + type
                this.accountitem.form.show = true
                this.accountitem.form.data = { ...item }
                this.images = item.images
                this.type = type
            },
            deleteItem (id) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.accountitem.form.loading = true
                        this._deleteItem(id)
                            .then(() => {
                                this._fetchItem(this.datauser.username).then((result) => {
                                    this.dataItemUser = result
                                })
                                this.accountitem.form.show = false
                                this.$swal(
                                    'Deleted!',
                                    'Your item has been deleted.',
                                    'success'
                                )
                            })
                            .finally(() => {
                                this.accountitem.form.loading = false
                            })
                    }
                })
            },
            onFileSelected (e, target) {
                this.accountitem.form.data[target] = e.target.files[0]
                this[target] = URL.createObjectURL(this.accountitem.form.data[target])
            },
            onChangeCVBg (e) {
                this.cv_background = e.target.files[0]
                this.cv_background_temp = URL.createObjectURL(this.cv_background)
            },
            generateCV () {
                this.$refs.html2Pdf.generatePdf()
            },
            onProgress (event) {
                if (event === 100) {}
            },
            handleChangeColor () {
                const $this = this
                axios.post('user/customize', { cv_color: this.cv_color }).then(function (response) {
                    if (response.data.success) {
                        $this.cv_color = response.data.result.cv_color
                        $this.$swal(
                            'Success!',
                            'Change color success.',
                            'success'
                        )
                    }
                })
            },
            changeBgShow () {
                this.$modal.show('change-background')
            },
            changeBgClose () {
                this.$modal.hide('change-background')
            },
            dragover (event) {
                event.preventDefault()
                // Add some visual fluff to show the user can drop its files
                if (!event.currentTarget.classList.contains('bg-gray-300')) {
                    event.currentTarget.classList.add('bg-gray-300')
                }
            },
            dragleave (event) {
                // Clean up
                event.currentTarget.classList.remove('bg-gray-300')
            },
            drop (event) {
                event.preventDefault()
                this.cv_background = event.dataTransfer.files[0]
                this.cv_background_temp = URL.createObjectURL(this.cv_background)
                // Clean up
                event.currentTarget.classList.remove('bg-gray-300')
            },
            updateBg () {
                const $this = this
                const formData = new FormData()
                formData.append('cv_bg', this.cv_background)
                axios.post('user/customize', formData).then(function () {
                    $this.changeBgClose()
                    $this.$swal(
                        'Success!',
                        'Change background success.',
                        'success'
                    )
                }).catch(function () {
                    $this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                })
            }
        },
        watch: {
            datauser: function () {
                if (this.datauser && this.datauser.cv_color !== '') {
                    this.cv_color = this.datauser.cv_color
                }
                if (this.datauser && this.datauser.cv_bg !== '') {
                    this.cv_background_temp = this.datauser.cv_bg
                }
                if (this.datauser) {
                    this._fetchItem(this.datauser.username).then((result) => {
                        this.dataItemUser = result
                    })
                }
            }
        },
        created () {
            this.$store.dispatch('auth/getUser')
                .catch(() => {
                    localStorage.removeItem('token')
                    if (this.layout === 'layout-app') this.$route.push('/')
                })
        }
    }
</script>
